export const assertUnreachable = (_x: never): never => {
    throw new Error("Didn't expect to get here");
};

export interface DocumentGrouping {
    pattern: RegExp;
    label: string;
}

export type TimePeriodCode =
    "DAYS-7"
    | "MONTHS-1"
    | "MONTHS-3"
    | "MONTHS-6"
    | "CALYEAR-0"
    | "CALYEAR-1"
    | "YEARS-1"
    | "YEARS-3"
    | "YEARS-5"
    | "GIVEN";
